<template>
  <View title="CV" >
    <Timeline class="mb-6" />
  </View>
</template>

<script>
import Timeline from '@/components/Timeline'
import View from '@/components/layout/View'

export default {
  components: {
    Timeline,
    View
  }
}
</script>
